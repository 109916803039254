export const setGlobalItem = (key: any, value: any) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeGlobalItem = (key: string) => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
};

export const clearAllGlobalItem = (key: string) => {
  if (typeof window !== "undefined") {
    window.localStorage.clear();
  }
};

export const getGlobalItem = (key: string) => {
  if (typeof window !== "undefined") {
    const value = window.localStorage.getItem('@token');

    if (value) return JSON.parse(window.localStorage.getItem('@token') ?? "");
  }
  return "";
};
