import { Iaction } from '../Interface';
import {
	REPORT_ALL_FAILED,
	REPORT_ALL_SUCCESS,
	REPORT_CLEAR,
	REPORT_FAILED,
	REPORT_SUCCESS
} from '../Types/report.types';

const reportReducers = (state = [], action: Iaction) => {
	switch (action.type) {
		case REPORT_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case REPORT_FAILED:
			return {
				...state,
				payload: action
			};
		case REPORT_CLEAR:
			return {
				...action.payload
			};
		case REPORT_ALL_SUCCESS:
			return {
				...action.payload
			};
		case REPORT_ALL_FAILED:
			return {
				...action.payload
			};
		default:
			return state;
	}
};

export default reportReducers;
