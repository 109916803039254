import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';

const Register = ({ }) => {
  return (
    <div className="bg-[#f9fafc] min-w-screen min-h-screen flex items-center justify-center">
      <div className="w-full max-w-[600px] px-4">
        <div>
          <Link to="/">   <button className="text-[#5048e5] py-[9px] px-4 rounded-lg hover:bg-[#5048e50a] transition-all  font-semibold text-sm"><FontAwesomeIcon className="mr-2" icon={faArrowLeftLong} />Dashboard</button></Link>
        </div>
        <form>
          <div className="py-6">
            <h4 className="text-3xl sm:text-[2rem] text-[#121828] font-bold">Create a new account</h4>
            <p className="text-sm text-[#65748b] font-normal mb-[0.35em]">Use your email to create a new account</p>
          </div>
          <div className="mt-4 mb-2">
            <TextField
              required
              id="outlined-required"
              label="Center Name"
              sx={{ width: '100%' }}
            />
          </div>
          <div className="mt-4 mb-2">
            <TextField
              required
              id="outlined-required"
              label="Last Name"
              sx={{ width: '100%' }}
            />
          </div>
          <div className="mt-4 mb-2">
            <TextField
              required
              id="outlined-required"
              label="Email Address"
              type="email"
              sx={{ width: '100%' }}
            />
          </div>
          <div className="mt-4 mb-2">
            <TextField
              required
              id="outlined-required"
              label="Password"
              type="password"
              sx={{ width: '100%' }}
            />
          </div>
          <div className="">
            <FormControlLabel control={<Checkbox />} label={<div className="text-sm text-[#65748B] font-normal">I have read the <Link to="/" className="text-[#5048e5] underline">Terms and Conditions</Link></div>} />
          </div>
          <div className="py-4"><button className="text-center w-full font-semibold text-[15px] px-6 py-[9px] bg-[#5048e5] text-white hover:bg-[#3832a0]  transition-all rounded-lg">Sign Up Now</button></div>
          <div>
            <p className="text-sm text-[#65748b] font-normal mb-[0.35em]">Have an account? <Link to="/login" className="text-[#5048e5] hover:underline">Sign In</Link></p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register