import React, { useEffect } from 'react'
import AdminCard from '../component/AdminCard'
import Banner from '../component/Banner'
import ContactInfo from '../component/ContactInfo'
import Header from '../component/Header'
import Services from '../component/Services'
import { useDispatch, useSelector } from "react-redux";
import { clear_report } from '../store/actions/reportActionCreator'


const Home = () => {
  const state = useSelector((state: any) => state.reports)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clear_report())

  }, [])
  return (
    <>
  
      <Header />
      <Banner />
      <Services />
      <ContactInfo />

    </>
  )
}

export default Home