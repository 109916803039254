import React from 'react';
import Sidebar from './Sidebar';

export default function Billing() {
	return (
		<Sidebar>
			<div>Billing</div>
		</Sidebar>
	);
}
