import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faDeleteLeft as faDelete } from '@fortawesome/free-solid-svg-icons';
import { getLabs } from '../store/actions/labActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const headCells = [

	{
		id: 'calories',
		numeric: true,
		disablePadding: false,
		label: 'Lab Name'
	},
	{
		id: 'fat',
		numeric: true,
		disablePadding: false,
		label: 'User Id'
	},
	{
		id: 'carbs1',
		numeric: true,
		disablePadding: false,
		label: 'Phone number'
	}, {
		id: 'carbs2',
		numeric: true,
		disablePadding: false,
		label: 'Address'
	}, {
		id: 'carbs3',
		numeric: true,
		disablePadding: false,
		label: 'Edit'
	}, {
		id: 'carbs',
		numeric: true,
		disablePadding: false,
		label: 'Delete'
	},


];

function EnhancedTableHead(props: any) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: any) => (event: any) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow sx={{ background: '#F3F4F6' }}>
				<TableCell sx={{ borderBottom: '0px' }} padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts'
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						sx={{ borderBottom: '0px' }}
						key={headCell.id}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props: any) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
				}
			}}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
					Test Lab List
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
						<FontAwesomeIcon icon={faDelete} />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FontAwesomeIcon icon={faFilter} />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export default function UserTable() {
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const dispatch = useDispatch();

	const { payload } = useSelector((state: any) => state.labs);
	console.log(payload);

	useEffect(() => {
		dispatch(getLabs());
	}, []);

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectAllClick = (event: any) => {
		if (event.target.checked) {
			const newSelecteds: any = payload?.map((n:any) => n?.lab_name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};
	const handleRequestSort = (event: any, property: any) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payload.length) : 0;
	return (
		<Box className="w-full mt-6">
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={payload?.length}
						/>
						<TableBody>
							{payload && payload?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row: any, index: any) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.name}>

											<TableCell
												sx={{ borderBottom: '0px' }}
												component="th"
												id={labelId}
												scope="row"
												padding="none"
											>
												{row.name}
											</TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>{row.lab_name}</TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>{row.userId}</TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>{row.phone_number}</TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>{row.address}</TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>
												<IconButton>
													<EditIcon />
												</IconButton></TableCell>
											<TableCell sx={{ borderBottom: '0px' }}>	<IconButton>
												<DeleteIcon />
											</IconButton></TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows
									}}
								>
									<TableCell sx={{ borderBottom: '0px' }} colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={payload?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
