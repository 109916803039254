import { Iaction } from '../Interface';
import { LAB_SUCCESS } from '../Types/lab.types';

const labReducer = (state = [], action: Iaction) => {
	switch (action.type) {
		case LAB_SUCCESS:
			return {
				...state,
				payload: action.payload
			};
		case LAB_SUCCESS:
			return {
				...state,
				payload: action
			};
		default:
			return state;
	}
};

export default labReducer;
