import { Iaction } from '../Interface';
import { STATE_CLEAR, STATE_FAILED, STATE_SUCCESS } from '../Types/state.types';

const reportReducers = (state = [], action: Iaction) => {
	switch (action.type) {
		case STATE_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case STATE_FAILED:
			return {
				...state,
				payload: action
			};
		case STATE_CLEAR:
			return {
				...action.payload
			};
		default:
			return state;
	}
};

export default reportReducers;
