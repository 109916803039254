import axios from 'axios';
import { BASE_URL } from './config';
import { getGlobalItem } from './localStorage';
const accessToken: any = localStorage.getItem('@token');

export const instance = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: JSON.parse(accessToken)?.accessToken
	}
});

instance.interceptors.request.use((config: any) => {
	if (!config.headers['Authorization']) {
		config.headers['Authorization'] = getGlobalItem('accessToken');
	}
	return config;
});
