import { Iaction } from '../Interface';
import { GET_AUTH_SUCCESS, LOGIN_USER } from '../Types/auth.types';

const initState = {
	userData: null,
	followingArtists: [],
	userLoading: true,
	isUsernameDialog: false,
	userauthtoken: null,
	isLoggedIn: false
};

const authReducer = (state = [], action: Iaction) => {
	switch (action.type) {
		case LOGIN_USER:
			return {
				...state,
				payload: action.payload,
				isLoggedIn: true
			};
		case GET_AUTH_SUCCESS:
			return {
				...state,
				payload: action.payload,
				isLoggedIn: true
			};
		default:
			return state;
	}
};

export default authReducer;
