import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { loginUser } from '../store/actions/authActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const [ email, setEmail ] = React.useState<string>('');
	const [ password, setPassword ] = React.useState<string>('');
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector((state: any) => state.auth);
	const Navigate = useNavigate();

	const handleLogin = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(loginUser({ email, password }));
	};

	useEffect(
		() => {
			if (isLoggedIn == true) {
				Navigate('/dashboard');
			}
		},
		[ isLoggedIn ]
	);

	return (
		<div className="bg-[#f9fafc] min-w-screen min-h-screen flex items-center justify-center">
			<div className="w-full max-w-[600px] px-4">
				<div>
					<Link to="/">
						{' '}
						<button className="text-[#5048e5] py-[9px] px-4 rounded-lg hover:bg-[#5048e50a] transition-all  font-semibold text-sm">
							<FontAwesomeIcon className="mr-2" icon={faArrowLeftLong} />Dashboard
						</button>
					</Link>
				</div>
				<form onSubmit={handleLogin}>
					<div className="py-6">
						<h4 className="text-3xl sm:text-[2rem] text-[#121828] font-bold">Sign in</h4>
						<p className="text-sm text-[#65748b] font-normal mb-[0.35em]">
							Sign in on the internal platform
						</p>
					</div>
					<div className="mt-4 mb-2">
						<TextField
							required
							onChange={(e: any) => {
								setEmail(e.target.value);
							}}
							type="email"
							id="outlined-required"
							label="Email Address"
							sx={{ width: '100%' }}
						/>
					</div>
					<div className="mt-4 mb-2">
						<TextField
							onChange={(e: any) => {
								setPassword(e.target.value);
							}}
							required
							id="outlined-required"
							label="Password"
							type="password"
							sx={{ width: '100%' }}
						/>
					</div>
					<div className="py-4">
						<button className="text-center w-full font-semibold text-[15px] px-6 py-[9px] bg-[#5048e5] text-white hover:bg-[#3832a0]  transition-all rounded-lg">
							Login
						</button>
					</div>
					<div>
						{/* <p className="text-sm text-[#65748b] font-normal mb-[0.35em]">Don't have an account? <Link to="/register" className="text-[#5048e5] hover:underline">Sign Up</Link></p> */}
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
