import React from 'react';
import Sidebar from './Sidebar';

export default function PerformanceLogs() {
	return (
		<Sidebar>
			<div>PerformanceLogs</div>
		</Sidebar>
	);
}
