import React from 'react'
const Banner = () => {
  return (
    <div className="relative">
      <img className="h-[80vh] w-full object-cover" src="./Images/banner_image.jpg" alt="" />
      <div className="w-full h-full bg-black opacity-30 absolute left-0 top-0"></div>
      <div className="absolute top-1/2 -translate-y-2/4 sm:left-10 left-6 text-white">
        <h1 className="text-3xl sm:text-6xl mb-4 font-bold">Lorem Ipsum</h1>
        <p className="max-w-[500px] text-base sm:text-lg">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p>
      </div>
    </div>
  )
}

export default Banner