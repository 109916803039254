import { useEffect } from "react";
import Home from "./pages/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import 'react-toastify/dist/ReactToastify.css'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './styles/index.css'
import FormComponent from "./component/FormComponent";
import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/Dashboard"
import Account from "./pages/Account";
import Requisitions from "./component/Requisitions";
import Billing from "./component/Billing";
import Users from "./component/Users";
import PerformanceLogs from "./component/PerformanceLogs";
import Orders from "./component/orders";
import { getLoggedAuth } from "./store/actions/authActionCreator"

function App() {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state: any) => state.auth)
 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/create-report" element={<FormComponent />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Account />} />
          <Route path="/requisitions" element={<Requisitions />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/logs" element={<PerformanceLogs />} />
          <Route path="/users" element={<Users />} />
          <Route path="/order" element={<Orders />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer style={{ zIndex: "9999999999999" }} />

    </>

  );
}
export default App;
