import { instance } from '../../config/apiConfig';
import { Ilogin } from '../Interface';
import { LOGIN_USER, FAILD_USER, GET_AUTH_SUCCESS, GET_AUTH_FAILED } from '../Types/auth.types';

export const loginUser = (payload: Ilogin) => async (dispatch: any) => {
	try {
		const UserData: any = await instance.post('/user_login', payload);
		localStorage.setItem('@token', JSON.stringify(UserData.data));
		return dispatch({
			type: LOGIN_USER,
			payload: UserData,
			isLoggedIn: true
		});
	} catch (Error) {
		return dispatch({
			type: FAILD_USER,
			isLoggedIn: false
		});
	}
};

export const getLoggedAuth = () => async (dispatch: any) => {
	try {
		const UserData: any = await instance.get('/getauth');
		return dispatch({
			type: GET_AUTH_SUCCESS,
			payload: UserData?.data,
			isLoggedIn: true
		});
	} catch (Error) {
		return dispatch({
			type: GET_AUTH_FAILED,
			isLoggedIn: false
		});
	}
};
