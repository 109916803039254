import React from 'react';
import UserTable from '../../component/UserTable';
import Sidebar from '../../component/Sidebar';
import AdminCard from '../../component/AdminCard';

export default function index() {
	return (
		<Sidebar>
			<div className="pt-32 min-h-screen bg-[#f9fafc] px-4 sm:px-6">
				<AdminCard />
				<UserTable />
			</div>
		</Sidebar>
	);
}
