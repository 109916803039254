import { instance } from '../../config/apiConfig';
import { LAB_SUCCESS, LAB_FAILED } from '../Types/lab.types';

export const getLabs = () => async (dispatch: any) => {
    try {
        const labData: any = await instance.get('/lab/get_labs');
        return dispatch({
            type: LAB_SUCCESS,
            payload: labData?.data.labData,
            isLoggedIn: true
        });
    } catch (Error) {
        return dispatch({
            type: LAB_FAILED,
            isLoggedIn: false
        });
    }
};
