import { instance } from '../../config/apiConfig';
import { REPORT_ALL_FAILED, REPORT_ALL_SUCCESS, REPORT_CLEAR, REPORT_FAILED, REPORT_SUCCESS } from '../Types/report.types';

export const create_reports = (payload: any) => async (dispatch: any) => {
    try {
        const reportData: any = await instance.post('/report/create_report', payload);
        return dispatch({
            type: REPORT_SUCCESS,
            payload: reportData?.data,
            isLoggedIn: true
        });
        return reportData
    } catch (Error) {
        return dispatch({
            type: REPORT_FAILED,
            isLoggedIn: false
        });
    }
};
export const clear_report = () => async (dispatch: any) => {
    return dispatch({
        type: REPORT_CLEAR,
        payload: [],
    });
};
export const getReportList = () => async (dispatch: any) => {
    try {
        const reportData: any = await instance.get('/report/get_reports');
        console.log(reportData,"stateDatastateData")
        return dispatch({
            type: REPORT_ALL_SUCCESS    ,
            payload: reportData?.data,
        });
    } catch (Error) {
        return dispatch({
            type: REPORT_ALL_FAILED,
        });
    }
};


export const deleteReportById = (_id:any) => async (dispatch: any) => {
    try {
        const reportData: any = await instance.delete(`/report/delete/${_id}`);
        console.log(reportData,"stateDatastateData")
        return dispatch({
            type: REPORT_ALL_SUCCESS    ,
            payload: reportData?.data,
        });
    } catch (Error) {
        return dispatch({
            type: REPORT_ALL_FAILED,
        });
    }
};