import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHome, faFileInvoice, faUserGear, faArrowTrendUp, faClockRotateLeft, faCircleCheck, faBars, faSort, faMagnifyingGlass, faUserGroup, faBell, faRightFromBracket, faChartColumn, faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const Sidebar = ({ children }: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleClass = () => {
    setActive(!active); 
  };
  return (
    <div className={`${active ? "active" : "inactive"} flex`}>
      <div className={`${active ? "fixed w-full h-screen bg-slate-900 opacity-25 z-10 transition-all" : ""}`}></div>
      <div className={`${active ? "min-w-[279px] w-[279px] overflow-auto z-10 left-0" : "xl:left-0 -left-[280px]"} duration-150 xl:relative fixed xl:min-w-[279px] xl:w-[279px] xl:overflow-auto transition-all h-screen bg-[#111827] text-white`}>
        <div className="border-b border-[#2D3748] py-6">
          <div className="px-4">
            <div className="mb-6 flex items-center justify-between">
              <FontAwesomeIcon icon={faUser} />
              <button onClick={toggleClass} className="xl:hidden p-2 h-9 w-9 text-[#6B7280] hover:bg-[#6b72800a] rounded-full">
                <FontAwesomeIcon className="w-4 h-4" icon={faBars} />
              </button>
            </div>
            <div className="py-[11px] px-6 bg-[#ffffff0a] rounded-lg flex justify-between items-center">
              <div>
                <h6>Acme Inc</h6>
                <p className="text-[#9CA3AF] text-sm">Your tier : Premium</p>
              </div>
              <div><FontAwesomeIcon icon={faSort} /></div>
            </div>
          </div>
        </div>
        <div>
          <ul className="px-4 py-6">
            <li>
              <Link to="/" className="py-[9px] px-4 text-[#D1D5DB] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faHome} />
                </span>
                <span className="text-sm">Home</span>
              </Link>
            </li>
            <li>
              <Link to="/requisitions" className="py-[9px] px-4 text-[#D1D5DB] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                </span>
                <span className="text-sm">Requisitions</span>
              </Link>
            </li>
            <li>
              <Link to="/order" className="py-[9px] px-4 text-[#d7e4f7] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                <span className="text-sm">Completed Orders</span>
              </Link>
            </li>
            <li>
              <Link to="/billing" className="py-[9px] px-4 text-[#d7e4f7] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faFileInvoice} />
                </span>
                <span className="text-sm">Billing</span>
              </Link>
            </li>
            <li>
              <Link to="/users" className="py-[9px] px-4 text-[#d7e4f7] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faUserGear} />
                </span>
                <span className="text-sm">User Management</span>
              </Link>
            </li>
            <li>
              <Link to="/logs" className="py-[9px] px-4 text-[#d7e4f7] block hover:bg-[#ffffff14] active:bg-[#ffffff14] active:text-[#10B981] rounded-lg">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faBagShopping} />
                </span>
                <span className="text-sm">Performance/Logs</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full">
        <div className="bg-white fixed shadow-md content-side" >
          <div className="px-4 flex justify-between items-center h-[64px]">
            <div>
              <button onClick={toggleClass} className="xl:hidden p-2 h-9 w-9 text-[#6B7280] hover:bg-[#6b72800a] rounded-full">
                <FontAwesomeIcon className="w-4 h-4" icon={faBars} />
              </button>
              <button className="p-2 h-9 w-9 text-[#6B7280] hover:bg-[#6b72800a] rounded-full">
                <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
              </button>
              <input type="text" className="outline-none sm:w-auto w-14" placeholder="Search" />
            </div>
            <div className="flex items-center">
              <button className="p-2 h-9 w-9 text-[#6B7280] hover:bg-[#6b72800a] rounded-full">
                <FontAwesomeIcon className="w-4 h-4" icon={faUserGroup} />
              </button>
              <button className="p-2 h-9 w-9 text-[#6B7280] hover:bg-[#6b72800a] rounded-full relative">
                <FontAwesomeIcon className="w-4 h-4" icon={faBell} />
                <span className="w-2 h-2 bg-[#5048E5] absolute rounded-full right-1 top-1"></span>
              </button>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 40, height: 40 }}><img className="rounded-full w-full h-full" src="https://material-kit-react.devias.io/static/images/avatars/avatar_1.png" alt="avtar" /></Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <ul className="px-3 py-1 min-w-[160px]">
                  <li>
                    <Link to="/profile" className="py-[9px] px-4 text-[#6B7280] flex items-center hover:bg-[#f1f1f1] rounded-lg">
                      <span className="mr-2">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <span className="text-sm">Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="py-[9px] px-4 text-[#6B7280] flex items-center hover:bg-[#f1f1f1] rounded-lg">
                      <span className="mr-2">
                        <FontAwesomeIcon icon={faRightFromBracket} />
                      </span>
                      <span className="text-sm">Logout</span>
                    </Link>
                  </li>
                </ul>
              </Menu>
            </div>
          </div>
        </div>
        <div className="">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Sidebar