import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer';
import labReducer from './reducers/labReducer';
import reportReducer from './reducers/reportReducer';
import stateReducer from './reducers/stateReducer';

const rootReducer = combineReducers({
	auth: authReducer,
	labs: labReducer,
	reports: reportReducer,
	state: stateReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
