import React, { useState } from 'react';

export const FormContext = React.createContext({
	form: {}
});

function Form(props: any) {
	const { children, submit = () => { }, initialValues }: any = props;

	const [form, setForm]: any = useState(initialValues);

	const handleFormChange = (event: any) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		
		});
	};
	const value = { form, handleFormChange };
	return (
		<form className="Form">
			<FormContext.Provider value={value}>{children}</FormContext.Provider>
			<div className="py-4">
				<button
					type="button"
					onClick={() => submit(form)}
					className="text-center w-full font-semibold text-[15px] px-6 py-[9px] bg-[#5048e5] text-white hover:bg-[#3832a0]  transition-all rounded-lg"
				>
					Submit
				</button>
			</div>
		</form>
	);
}

export default Form;
