import React from 'react';
import Modal from '@mui/material/Modal';

import {
	Grid,
	Card,
	CardContent,
	MenuItem,
	InputLabel,
	Select,
	CardActions,
	CardHeader,
	FormControl
} from '@material-ui/core';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Button } from '@mui/material';

//Data

const initialValues = {
	firstName: '',
	lastName: '',
	occupation: '',
	city: '',
	country: '',
	email: '',
	password: ''
};

const options = [
	{ label: 'Computer Programmer', value: 'Computer_programmer' },
	{ label: 'Web Developer', value: 'web_developer' },
	{ label: 'User Experience Designer', value: 'user_experience_designer' },
	{ label: 'Systems Analyst', value: 'systems_analyst' },
	{ label: 'Quality Assurance Tester', value: 'quality_assurance_tester' }
];

//password validation
const lowercaseRegEx = /(?=.*[a-z])/;
const uppercaseRegEx = /(?=.*[A-Z])/;
const numericRegEx = /(?=.*[0-9])/;
const lengthRegEx = /(?=.{6,})/;

//validation schema
let validationSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string()
		.matches(lowercaseRegEx, 'Must contain one lowercase alphabetical character!')
		.matches(uppercaseRegEx, 'Must contain one uppercase alphabetical character!')
		.matches(numericRegEx, 'Must contain one numeric character!')
		.matches(lengthRegEx, 'Must contain 6 characters!')
		.required('Required!')
});

export default function EditReport({ open, handleClose, handleOpen }: any) {
	const onSubmit = (values: any) => {
		console.log(values);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Grid container justify="center" spacing={1} style={{ marginTop: '95px' }}>
				<Grid item md={6}>
					<Card>
						<CardHeader title="Report Edit" />
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
							{({ values, handleChange, handleBlur }) => {
								return (
									<Form>
										<CardContent>
											<Grid item container spacing={1} justify="center">
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="First Name"
														variant="outlined"
														fullWidth
														name="firstName"
														value={values.firstName}
														component={TextField}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="Last Name"
														variant="outlined"
														fullWidth
														name="lastName"
														value={values.lastName}
														component={TextField}
													/>
												</Grid>

												<Grid item xs={12} sm={6} md={12}>
													<FormControl fullWidth variant="outlined">
														<InputLabel id="demo-simple-select-outlined-label">
															Occupation
														</InputLabel>
														<Select
															labelId="demo-simple-select-outlined-label"
															id="demo-simple-select-outlined"
															label="Occupation"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.occupation}
															name="occupation"
														>
															<MenuItem>None</MenuItem>
															{options.map((item) => (
																<MenuItem key={item.value} value={item.value}>
																	{item.label}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="City"
														variant="outlined"
														fullWidth
														name="city"
														value={values.city}
														component={TextField}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="Country"
														variant="outlined"
														fullWidth
														name="country"
														value={values.country}
														component={TextField}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="Email"
														variant="outlined"
														fullWidth
														name="email"
														value={values.email}
														component={TextField}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Field
														label="Password"
														variant="outlined"
														fullWidth
														name="password"
														value={values.password}
														type="password"
														component={TextField}
													/>
												</Grid>
											</Grid>
										</CardContent>
										<CardActions>
											<Button type="submit" style={{ margin: 'auto' }}>
												REGISTER
											</Button>
										</CardActions>
									</Form>
								);
							}}
						</Formik>
					</Card>
				</Grid>
			</Grid>
		</Modal>
	);
}
