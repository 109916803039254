import React from 'react'

const Services = () => {
  return (
    <>
      <section className="text-[#65748B] body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center pb-10">
            <h2 className="text-4xl font-bold text-[#111827] w-fit mx-auto border-b-2 border-[#D14343] pb-2">Services</h2>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-[#65748B] mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-[#D14343] mb-3">The Catalyzer</h1>
                  <p className="leading-relaxed mb-3 text-[#111827]">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>

                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/721x401" alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-[#65748B] mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-[#D14343] mb-3">The 400 Blows</h1>
                  <p className="leading-relaxed mb-3 text-[#111827]">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>

                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/722x402" alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-[#65748B] mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-[#D14343] mb-3">Shooting Stars</h1>
                  <p className="leading-relaxed mb-3 text-[#111827]">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services