import { useContext } from 'react';
import { FormContext } from './form';


const FormInput = (props: any) => {
	const { label, type = 'text', name, flag, value, extraClass, change } = props;
	const formContext = useContext(FormContext);
	const { form, handleFormChange }: any = formContext;

	return (
		<div>
			{flag !== 'select' ? <div className="">
				<div className="">
					<div className="mt-4 mb-2">
						<label>{label}</label>
						<input
							{...change}
							type={type}
						
							className={`"rounded bg-[#fcfcfb] border border-[#ced4da] py-3 px-[10px] w-full outline-none transition-all duration-150 shadow-focus focus:border-[#1976d2] ${extraClass}"`}
						/>
					</div>
				</div>
			</div>
				: <div className="w-full">
					<div className="w-full">
						<div className="mt-4 mb-2">
							<label className="">{label}</label>
							<select
								{...change}
							
								className="rounded bg-[#fcfcfb] border border-[#ced4da] py-3 px-[10px] w-full outline-none transition-all duration-150 shadow-focus focus:border-[#1976d2]"
							>
								{value?.map((data: any) => {
									return <option value={data?.id}>{data?.lab_name}</option>
								})}
							</select>
						</div>
					</div>
				</div>}
		</div>
	);
}

export default FormInput;
