import React, { useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Sidebar from '../../component/Sidebar';
import AccountProfile from '../../component/AccountProfile';
import AccountProfileDetails from '../../component/AccountProfileDetails';
import { getLoggedAuth } from '../../store/actions/authActionCreator';
import { useDispatch, useSelector } from 'react-redux';

const Account = () => {
	const dispatch = useDispatch();

	const { payload, isLoggedIn } = useSelector((state: any) => state.auth);
	console.log(payload, 'isLoggedInisLoggedIn');
	useEffect(
		() => {
			if (isLoggedIn && payload) {
				dispatch(getLoggedAuth());
			}
		},
		[ payload, isLoggedIn ]
	);

	return (
		<Sidebar>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="lg">
					<Typography sx={{ mb: 3 }} variant="h4">
						Account
					</Typography>
					<Grid container spacing={3}>
						<Grid item lg={4} md={6} xs={12}>
							<AccountProfile payload={payload}/>
						</Grid>
						<Grid item lg={8} md={6} xs={12}>
							<AccountProfileDetails payload={payload}/>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Sidebar>
	);
};

export default Account;
