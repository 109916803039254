import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserGroup, faMoneyBill1, faChartColumn, faDollarSign } from '@fortawesome/free-solid-svg-icons'

const AdminCard = () => {
  return (
    <>
      <div className="xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid gap-6">
        <div className="bg-white py-8 px-6 shadow-sm rounded-lg">
          <div className="flex justify-between ">
            <div>
              <p className="uppercase text-[#65748B] mb-[0.35em] text-xs font-semibold">Pending Orders</p>
              <h2 className="text-[2rem] font-bold">$24k</h2>
            </div>
            <div className="w-14 h-14 flex items-center justify-center bg-[#D14343] text-white rounded-full">
              <FontAwesomeIcon className="w-5 h-5" icon={faMoneyBill1} />
            </div>
          </div>
          <div className="pt-4">
            <p className="text-xs text-[#65748B]"><span className="text-[#D14343] text-sm  font-normal"><FontAwesomeIcon className="w-4 h-4" icon={faUser} /> 12% </span>Since last month</p>
          </div>
        </div>
        <div className="bg-white py-8 px-6 shadow-sm rounded-lg">
          <div className="flex justify-between ">
            <div>
              <p className="uppercase text-[#65748B] mb-[0.35em] text-xs font-semibold">Completed Orders</p>
              <h2 className="text-[2rem] font-bold">1,6k</h2>
            </div>
            <div className="w-14 h-14 flex items-center justify-center bg-[#14B8A6] text-white rounded-full">
              <FontAwesomeIcon className="w-5 h-5" icon={faUserGroup} />
            </div>
          </div>
          <div className="pt-4">
            <p className="text-xs text-[#65748B]"><span className="text-[#121828] text-sm font-normal"><FontAwesomeIcon className="w-4 h-4 text-[#14B8A6]" icon={faUser} /> 16% </span>Since last month</p>
          </div>
        </div>
        <div className="bg-white py-8 px-6 shadow-sm rounded-lg">
          <div className="flex justify-between ">
            <div>
              <p className="uppercase text-[#65748B] mb-[0.35em] text-xs font-semibold">TASKS PROGRESS</p>
              <h2 className="text-[2rem] font-bold">75.5%</h2>
            </div>
            <div className="w-14 h-14 flex items-center justify-center bg-[#FFB020] text-white rounded-full">
              <FontAwesomeIcon className="w-5 h-5" icon={faChartColumn} />
            </div>
          </div>
          <div className="pt-4">
            <div className="w-full h-1 bg-[#bcb9f5]">
              <span className="w-9/12 h-1 bg-[#5048e5] block"></span>
            </div>
          </div>
        </div>
        <div className="bg-white py-8 px-6 shadow-sm rounded-lg">
          <div className="flex justify-between ">
            <div>
              <p className="uppercase text-[#65748B] mb-[0.35em] text-xs font-semibold">TOTAL PROFIT</p>
              <h2 className="text-[2rem] font-bold">$23k</h2>
            </div>
            <div className="w-14 h-14 flex items-center justify-center bg-[#5048E5] text-white rounded-full">
              <FontAwesomeIcon className="w-5 h-5" icon={faDollarSign} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminCard