import { Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import FormInput from '../Forms/formInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLabs } from '../store/actions/labActionCreator';
import { clear_report, create_reports } from '../store/actions/reportActionCreator';
import * as Yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { toast } from 'react-toastify';

const FormComponent = () => {
  const dispatch = useDispatch();
  const report = useSelector((state: any) => state.reports)
  const navigate = useNavigate();
  const [signatureFile, setSignatureFile] = React.useState<any>('')
  const [error, setError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { payload } = useSelector((state: any) => state.labs);
  const states = useSelector((state: any) => state.state)
  console.log(states,"statesstates")
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  useEffect(() => {
    dispatch(getLabs());
  }, []);
  const gender = [
    {
      lab_name: "Male"
    },
    {
      lab_name: "Female"
    },
    {
      lab_name: "Other"
    }
  ]
  const raceOption = [
    {
      lab_name: "American Indian"
    },
    {
      lab_name: "Asian"
    },
    {
      lab_name: "Black or African American"
    },
    {
      lab_name: "Hispanic or Latino"
    },
    {
      lab_name: "Other"
    },

  ]
  const EthnicityOption = [
    {
      lab_name: "Hispanic or Latino"
    },
    {
      lab_name: "Non Hispanic or Latino"
    },
    {
      lab_name: "Prefer Not to Say"
    },

  ]
  const validationSchema = Yup.object().shape({

    client_id: Yup.string()
      .required("client_id is required"),
    patient_fname: Yup.string()
      .required("First Name is required")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces allowed"),
    patient_lname: Yup.string()
      .required("Last Name  is required")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces allowed"),
    gender: Yup.string()
      .required("Gender is required"),
    dateOfBirth: Yup.string()
      .required("BirthDate is required"),
    race: Yup.string()
      .required("Race is required"),
    ethinicity: Yup.string()
      .required("Ethinicity is required"),
    email: Yup.string()
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("PhoneNumber is required")
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, "Please Enter Valid Mobile No."),
    streetAddress: Yup.string()
      .required("StreetAddress is required"),
    city: Yup.string()
      .required("City is required"),
    state: Yup.string()
      .required("State is required"),
    zipCode: Yup.string()
      .required("ZipCode is required"),
    Terms: Yup.boolean()
      .oneOf([true], "You must accept the pricing policy terms and conditions")
  })
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const onSubmit = async (data: any) => {
    setIsLoading(true)
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
    if (!signatureFile) {
      setError(true)
      setIsLoading(false)
      return
    }
    const form = new FormData()
    form.append("client_id", data.client_id)
    form.append("patient_fname", data.patient_fname)
    form.append("patient_lname", data.patient_lname)
    form.append("gender", data.gender)
    form.append("dateOfBirth", data.dateOfBirth)
    form.append("race", data.race)
    form.append("ethinicity", data.ethinicity)
    form.append("email", data.email)
    form.append("phoneNumber", data.phoneNumber)
    form.append("streetAddress", data.streetAddress)
    form.append("city", data.city)
    form.append("state", data.state)
    form.append("zipCode", data.zipCode)
    form.append("signatureFile", signatureFile[0])
    form.append("collectionDate", date)
    form.append("collectionTime", time)
    const datamain = await dispatch(create_reports(form))
  }
  useEffect(() => {
    if (report.status) {
      setIsLoading(false)

      navigate("/")
      toast.success("report submitted successfully", {
        position: "bottom-left",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }, [report])
  return (
    <div className="container mx-auto px-4">
      <form >
        <div className="py-6">
          <h4 className="text-3xl sm:text-[2rem] text-[#121828] font-bold">Covid 19 Testing </h4>
          <p className="text-sm text-[#65748b] font-normal mb-[0.35em]">Registration and virtual Check-in</p>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("client_id") }} label="Select client" name="client_id" flag="select" value={payload} />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.client_id?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("patient_fname") }} label="First Name" name="patientfname" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.patient_fname?.message}
            </p>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("patient_lname") }} label="Last Name" name="patient_lname" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.patient_lname?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("gender") }} label="Gender" name="patientfname" flag="select" value={gender} />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.patientfname?.message}
            </p>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("dateOfBirth") }} type="date" label="Date of Birth" name="dateOfBirth" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.dateOfBirth?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("race") }} label="Race" name="race" flag="select" value={raceOption} />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.race?.message}
            </p>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("ethinicity") }} label="Ethinicity" name="ethinicity" flag="select" value={EthnicityOption} />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.ethinicity?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormControl sx={{ width: '100%' }} variant="standard">
              <FormInput change={{ ...register("email") }} label="Email (Accuracy is important)" name="email" type="email" />
              <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
                {errors.email?.message}
              </p>
            </FormControl>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormControl sx={{ width: '100%' }} variant="standard">
              <FormInput change={{ ...register("phoneNumber") }} label="Phone Number" name="phoneNumber" type="text" />
              <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
                {errors.phoneNumber?.message}
              </p>
            </FormControl>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormControl sx={{ width: '100%' }} variant="standard">
              <FormInput change={{ ...register("streetAddress") }} label="Street address" name="streetAddress" type="text" />
              <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
                {errors.streetAddress?.message}
              </p>
            </FormControl>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("city") }} label="City" name="city" type="text" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.city?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("state") }} label="State" name="state" type="text" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.state?.message}
            </p>
          </div>
          <div className="md:col-span-6 col-span-12">
            <FormInput change={{ ...register("zipCode") }} label="Zip Code" name="zipCode" type="text" />
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {errors.zipCode?.message}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="md:col-span-6 col-span-12">
            <FormControl sx={{ width: '100%' }} variant="standard">
              <label className="text-[#00000099]">Test Selection</label>
              <FormControlLabel className="text-[#00000099]" control={<Checkbox checked={true} />} label="SARS-COV-PCR[COV19]" />
            </FormControl>
          </div>
        </div>
        <div className="grid gap-5">
          <div className="col-span-12">
            <label className="">Upload Insurance Card Or State Id</label>
            <div className="relative h-[200px] border-dashed border-2">
              <div className="absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4 text-center">
                <FontAwesomeIcon className="text-[#d6dfec] text-4xl" icon={faCloudArrowUp} />
                <h2 className="text-2xl font-semibold text-gray-900">Browse Files</h2>
                <p className="text-base text-[#00000099]">Drag and drop files here</p>
              </div>
              <input type="file" onChange={(e) => { setSignatureFile(e.target.files), setError(false) }} className="opacity-0 absolute h-full rounded bg-[#fcfcfb] border border-[#ced4da] py-[10px] px-[10px] w-full outline-none transition-all duration-150 shadow-focus focus:border-[#5048E5]" name="signatureFile" />
              <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
                {errors.signatureFile?.message}
              </p>
            </div>
            <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
              {error ? "Upload Insurance Card Or State Id" : ""}
            </p>
          </div>
        </div>
        <div className="">
          <FormControlLabel control={<Checkbox {...register("Terms")} />} label={<div className="text-sm text-[#65748B] font-normal">I have read the <Link to="/" className="text-[#5048e5] underline">Terms and Conditions</Link></div>} />
          <p className="text-[#D14343] text-[12px] tablet-300 mt-1">
            {errors.Terms?.message}
          </p>
        </div>
        <div>
        </div>
        <div className="py-4">
          <button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="text-center w-full font-semibold text-[15px] px-6 py-[9px] bg-[#5048e5] text-white hover:bg-[#3832a0]  transition-all rounded-lg"
          >
            Submit
          </button>
        </div>
      </form >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  )
}

export default FormComponent