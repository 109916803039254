import React from 'react';
import Sidebar from './Sidebar';


export default function Users() {
	return (
		<Sidebar>
			<div>Users</div>
		</Sidebar>
	);
}
