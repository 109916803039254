import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { getReportList,deleteReportById } from '../store/actions/reportActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import EditModal from "../component/modal/editReport"

const headCells = [
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		label: 'patient name'
	},
	{
		id: 'calories',
		numeric: true,
		disablePadding: false,
		label: 'email'
	},
	{
		id: 'fat',
		numeric: true,
		disablePadding: false,
		label: 'Number'
	},
	{
		id: 'carbs',
		numeric: true,
		disablePadding: false,
		label: 'Gender'
	},
	{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Race'
	},{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Ethinicity'
	},{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Address'
	},{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Zip code'
	},{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Edit'
	},{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'Delete'
	}
];

function EnhancedTableHead(props: any) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: any) => (event: any) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts'
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props: any) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
				}
			}}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
					Report list
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export default function Requisitions() {
	const [ order, setOrder ] = React.useState('asc');
	const dispatch = useDispatch();
	const [ orderBy, setOrderBy ] = React.useState('calories');
	const [ selected, setSelected ] = React.useState([]);
	const [ page, setPage ] = React.useState(0);
	const [ dense, setDense ] = React.useState(false);
	const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
    const [ open, setOpen ] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { reportData } = useSelector((state: any) => state.reports);
	console.log(reportData, 'reportdatareportdata');
	useEffect(
		() => {
			dispatch(getReportList());
		},
		[]
	);

    
	const handleRequestSort = (event: any, property: any) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: any) => {
		if (event.target.checked) {
			const newSelecteds: any = reportData?.map((n:any) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: any, name: any) => {
		const selectedIndex: any = selected;
		let newSelected: any = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

    const handleDelete =(id:any)=>{
        dispatch(deleteReportById(id))
        dispatch(getReportList());
    }
    const handleEdit =(id:any)=>{
        handleOpen()
    }
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reportData?.length) : 0;
	return (
        <>
		<Sidebar>
			<Box sx={{ width: '100%', marginTop: '90px' }}>
				<Paper sx={{ width: '97%', m: 2 }}>
					<Box>
						<EnhancedTableToolbar numSelected={selected.length} />
						<TableContainer>
							<Table
								sx={{ minWidth: 750 }}
								aria-labelledby="tableTitle"
								size={dense ? 'small' : 'medium'}
							>
								<EnhancedTableHead
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={reportData?.length}
								/>
								<TableBody>
									
									{reportData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row: any, index: any) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													onClick={(event) => handleClick(event, row.name)}
													role="checkbox"
													tabIndex={-1}
													key={row.name}
												>
													<TableCell padding="checkbox">
														<Checkbox
															color="primary"
															inputProps={{
																'aria-labelledby': labelId
															}}
														/>
													</TableCell>
													<TableCell component="th" id={labelId} scope="row" padding="none">
														{row.patient_fname + row.patient_lname}
													</TableCell>
													<TableCell align="right">{row.email}</TableCell>
													<TableCell align="right">{row.phoneNumber}</TableCell>
													<TableCell align="right">{row.gender}</TableCell>
													<TableCell align="right">{row.race}</TableCell>
													<TableCell align="right">{row.ethinicity}</TableCell>
													<TableCell align="right">{row.streetAddress}</TableCell>
													<TableCell align="right">{row.zipCode}</TableCell>
													<TableCell align="right" onClick={()=>handleEdit(row?.id)}><IconButton>
													<EditIcon />
												</IconButton></TableCell>
													<TableCell align="right" onClick={()=>handleDelete(row?.id)}><IconButton>
												<DeleteIcon />
											</IconButton></TableCell>
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: (dense ? 33 : 53) * emptyRows
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[ 5, 10, 25 ]}
							component="div"
							count={reportData?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Box>
				</Paper>
			</Box>
		</Sidebar>
        {open && <EditModal handleOpen={handleOpen}handleClose={handleClose} open={open} />}
        </>
	);
}
