import { Box, CardHeader, Container, Grid, TextField, Typography } from '@mui/material';
import { Avatar, Button, Card, CardActions, CardContent, Divider } from '@mui/material';
import { useState } from 'react';
import Sidebar from './Sidebar';


const user = {
	avatar: '/static/images/avatars/avatar_6.png',
	city: 'Los Angeles',
	country: 'USA',
	jobTitle: 'Senior Developer',
	name: 'Katarina Smith',
	timezone: 'GTM-7'
};

const AccountProfile = (payload: any) => (
	<Card >
		<CardContent>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<Avatar
					src={user.avatar}
					sx={{
						height: 64,
						mb: 2,
						width: 64
					}}
				/>
				<Typography color="textPrimary" gutterBottom variant="h5">
					{payload.full_name}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{`${user.city} ${user.country}`}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{user.timezone}
				</Typography>
			</Box>
		</CardContent>
		<Divider />
		<CardActions>
			<Button color="primary" fullWidth variant="text">
				Upload picture
			</Button>
		</CardActions>
	</Card>
);


export default AccountProfile